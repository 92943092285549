import React from "react";
// components
import { DefaultLayout } from "../components/layout/defaultLayout";
import { BreadcrumbBar } from "../components/bar/breadcrumbBar";
import { PreparationSection } from "../components/section/preparationSection";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "マイページ", url: "/mypage" },
];

const MypagePage = () => {
  return (
    <DefaultLayout pageTitle="マイページ">
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <PreparationSection txt="MYPAGE" />
    </DefaultLayout>
  );
};

export default MypagePage;
